<template>
    <div class="ingredient-configuration">
        <div class="gram-title">{{ $t('production.configuration.title') }}</div>
        <div class="ingredient">
            <div class="ingredients" v-for="product in tempProducts" :key="product.id">
                <button class="toggle-switch" @click="toggleTempProduct(product.id)" :class="{ active: product.status }">
                    <div :class="{ slider: true, active: product.status }"></div>
                </button>
                <div class="name">{{ product.name }}</div>
                <template v-if="product.containers">
                    <select v-model="product.active_container" @change="updateActiveContainer(product.id, product.active_container)" :disabled="Object.keys(product.containers).length <= 1">
                        <option v-for="container in product.containers" :key="container.id" :value="container.id">
                            {{ container.name }}
                        </option>
                    </select>
                </template>
                <template v-else-if="product.packages">
                    <select v-model="product.active_package" :disabled="Object.keys(product.packages).length <= 1">
                        <option v-for="container in product.packages" :key="container.id" :value="container.id">
                            {{ container.name }}
                        </option>
                    </select>
                </template>
            </div>
        </div>
        <div class="btnExit">
            <button class="btn cancel-changes" @click="cancelChanges()">{{ $t('production.configuration.cancel') }}</button>
            <button class="btn save-changes" @click="saveChanges()">{{ $t('production.configuration.saveChanges') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Configuration',
    props: {
        types: { type: Array }
    },
    data() {
        return {
            tempProducts: {}
        }
    },
    created() {
        this.loadProducts()
    },
    methods: {
        loadProducts() {
            this.tempProducts = JSON.parse(JSON.stringify(this.$store.getters['production/getProducts']))
            // filter only products that have param type in this.types
            if (this.types) {
                for (var item in this.tempProducts) {
                    if (!this.types.includes(this.tempProducts[item].type)) {
                        delete this.tempProducts[item]
                    }
                }
            }

            console.log('tempProducts', JSON.parse(JSON.stringify(this.$store.getters['production/getProducts'])))
        },
        cancelChanges() {
            this.loadProducts()
            this.$store.commit('production/setConfiguration', false)
        },
        saveChanges() {
            var products = []
            for (var item in this.tempProducts) {
                products.push({
                    id: item,
                    status: this.tempProducts[item].status ? 1 : 0,
                    container: this.tempProducts[item].active_container
                })
            }
            var params = {
                products: JSON.stringify(products)
            }
            console.warn(params)

            var self = this

            this.$overlay.loading()
            this.$store.dispatch('production/updateConfiguration', params).then(() => {
                self.$overlay.hide()
                self.$store.commit('production/setProducts', self.tempProducts)
                self.$store.commit('production/setConfiguration', false)
                // TODO - Probably we have to recalculate forecast (confirm)
            })
        },
        toggleTempProduct(productId) {
            if (this.tempProducts[productId]) {
                this.tempProducts[productId].status = !this.tempProducts[productId].status
            }
        },
        updateActiveContainer(productId, activeContainerId) {
            if (this.tempProducts[productId]) {
                this.tempProducts[productId].active_container = activeContainerId
            }
        }
    }
}
</script>
<style lang="scss">
.ingredient-configuration {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-bottom: 0;
}
.gram-title {
    font-family: $text-bold;
    @include font-size(l);
    margin-bottom: 16px;
}
.ingredient {
    height: 80vh;
    overflow-y: auto;

    .ingredients {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        padding: 12px 0;

        .toggle-switch {
            width: 40px;
            height: 24px;
            background-color: $color-selector2;
            border-radius: 18px;
            position: relative;
            transition: background-color 0.3s;
            border: none;
            padding: 0;
            margin-right: 5px;

            &.active {
                background-color: $color-primary-700;
            }

            .slider {
                width: 18px;
                height: 18px;
                background-color: $color-selector;
                border-radius: 50%;
                position: absolute;
                top: 3px;
                left: 4px;
                transition: left 0.3s;

                &.active {
                    left: 18px;
                }
            }
        }

        .name {
            font-family: $text;
            padding-left: 4px;
        }

        select {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 250px;
            font-family: $text;
            border: 1px solid #ccc;
            border-radius: 3px;
            padding: 8px 16px;
            color: $color-black;
        }
    }
}
.btnExit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    .btn {
        padding: 10px 20px;
        border: none;
        border-radius: 5px;
        margin-right: 10px;
        text-align: center;
        width: 200px;
    }
    .cancel-changes {
        font-family: $text-medium;
        color: $main-s10;
        background-color: $neutro;
        margin: 6px;
    }
    .save-changes {
        font-family: $text-bold;
        color: #fff;
        background-color: $main;
    }
}
</style>
