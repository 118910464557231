<template>
    <div id="topbar-container">
        <div id="summary" v-if="summary">
            <div v-if="summary.forecast" class="row">
                {{ $t('production.summary.forecast') }}: <span class="value">{{ summary.forecast }}€</span>
            </div>
            <div v-if="summary.current" class="row">
                {{ $t('production.summary.current') }}: <span class="value">{{ summary.current }}€</span>
            </div>
            <div v-if="summary.current_delta" class="row current">
                {{ $t('production.summary.current_sales') }}: <span class="value" :class="{ positive: summary.current_delta > 0, negative: summary.current_delta < 0, neutral: summary.current_delta == 0 }">{{ summary.current_delta }}%</span>
            </div>
            <!-- <div v-if="summary.last_update" class="row">
                {{ $t('production.summary.last_update') }}: <span class="value update">{{ summary.last_update }}</span>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Sidebar',
    props: { summary: { type: Object } },
    data() {
        return {}
    },
    computed: {},
    methods: {
        formatDate(date) {
            if (this.isValidDate(date)) {
                return moment(date).locale(localStorage.language).format('dddd').toUpperCase() + ' ' + moment(date).format('DD/MM')
            } else {
                const localWeekdays = moment.weekdays()
                const englishWeekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
                const weekdayIndex = englishWeekdays.indexOf(date)
                return localWeekdays[weekdayIndex].toUpperCase()
            }
        },
        isValidDate(dateString) {
            return moment(dateString, 'YYYY-MM-DD', true).isValid()
        }
    },
    created() {},
    watch: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#summary {
    width: 100%;
    bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 25px 15px;
    justify-items: start;
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    margin-bottom: 16px;
    border-radius: 4px;

    .row {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        gap: 15px;
        flex-wrap: nowrap;
        word-break: break-word;
        width: 100%;
        padding: 4px 0;
        @include font-size(m);
        line-height: 100%;

        .value {
            font-family: $text-bold;
            text-align: right;
            @include font-size(xl);

            &.update {
                font-family: $text;
            }
        }

        &.current {
            margin-top: 0px;
            .value {
                padding: 4px 8px;
                border-radius: 3px;
                color: #fff;
                width: auto;
                min-width: 90px;
                text-align: center;
                &.positive {
                    background-color: $color-success-500;
                }
                &.negative {
                    background-color: $color-error-500;
                }
                &.neutral {
                    background-color: $color-neutral-500;
                }
            }
        }
    }
}

@media (orientation: portrait) {
    #summary {
        .row {
            .value {
                @include font-size(l);
                padding: 8px;
            }
            &.current {
                .value {
                    padding: 8px 16px;
                }
            }
        }
    }
}
</style>
