<template>
    <div id="pls-init">
        <div class="content">
            <div class="title">{{ $t('pls.init.title', { date: formatDate(date) }) }}</div>
            <div class="subtitle">{{ $t('pls.init.subtitle') }}</div>

            <div class="btnExit">
                <button class="btn" @click="prepareForecast()">{{ $t('pls.init.action') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    name: 'Init',
    props: { date: { type: String } },
    data() {
        return {}
    },
    computed: {},
    created() {},
    methods: {
        formatDate(date) {
            if (this.isValidDate(date)) {
                return moment(date).locale(localStorage.language).format('dddd').toUpperCase() + ' ' + moment(date).format('DD/MM')
            } else {
                const localWeekdays = moment.weekdays()
                const englishWeekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

                const weekdayIndex = englishWeekdays.indexOf(date)

                return localWeekdays[weekdayIndex].toUpperCase()
            }
        },
        isValidDate(dateString) {
            return moment(dateString, 'YYYY-MM-DD', true).isValid()
        },
        prepareForecast() {
            this.$router.push({ name: 'Production', params: { date: this.date } })
        }
    }
}
</script>
<style lang="scss" scoped>
#pls-init {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0;
    overflow: hidden;
    white-space: nowrap;
    justify-content: center;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 16px;

        .title {
            font-family: $text-bold;
            @include font-size(l);
            margin-bottom: 64px;
            text-align: center;
            margin: 0.8rem 0 1rem 0;
        }

        .btnExit {
            height: 10%;
            margin-top: 40px;
            .btn {
                padding: 0px;
            }
        }
    }
}
</style>
