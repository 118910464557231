<template>
    <div class="table">
        <div :class="['topbar', { expanded: isExpanded }]">
            <div class="sales-information">
                <!--<button class="nav sales-button" :class="{ active: isExpanded }" @click="toggleSalesSection"></button>-->
                <button class="nav refresh" @click="refresh"></button>
            </div>

            <div class="content">
                <div class="navBar">
                    <button class="nav left" @click="scrollLeft"></button>

                    <div class="hours" ref="scrollContainer1">
                        <div v-for="(value, hour) in summary_hours2" :key="hour" class="nav-elements" :class="{ increase: value == 1, decrease: value == -1 }">
                            <div
                                class="timeframe"
                                :class="{
                                    current: currentTimeframe == hour
                                }"
                            >
                                <span class="title">{{ hour }}</span>
                                <div class="subtitles">
                                    <span class="subtitle">Baños</span>
                                    <span class="subtitle">Unid.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="nav right" @click="scrollRight"></button>
                </div>
            </div>
        </div>
        <div class="content-sections hide-scrollbar" ref="scrollContainer4">
            <div class="product-content">
                <div class="products">
                    <div class="item" v-for="product in filteredProducts" :key="product.id">
                        <div class="icon" :style="{ backgroundImage: 'url(' + product.image + ')' }"></div>
                        <div class="content">
                            <div class="name">
                                {{ product.name }}
                            </div>
                            <div class="container">{{ product.packages[product.active_container] ? product.packages[product.active_container].name : '' }}</div>
                        </div>
                    </div>
                </div>
                <div class="content">
                    <div class="forecast">
                        <div class="item" v-for="product in filteredProducts" :key="product.id" ref="scrollContainer">
                            <div
                                v-for="(value, hour) in summary_hours2"
                                :key="hour"
                                class="value-container"
                                :class="{
                                    current: currentTimeframe == hour
                                    // increase: currentTimeframe != hour && getTotal(product.id)[hour].state == 'increase',
                                    // decrease: currentTimeframe != hour && getTotal(product.id)[hour].state == 'decrease'
                                }"
                            >
                                <div class="value baths">{{ getTotal(product.id)[hour].baths }}</div>
                                <div class="value">{{ getTotal(product.id)[hour].units }}</div>
                                <!-- <span class="updated up"></span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { indexOf } from 'lodash'

export default {
    name: 'productionView',
    data() {
        return {
            isExpanded: false,
            start_hour: 0,
            max_hours: 3,
            selectedShift: 'morning',
            currentTime: new Date()
        }
    },
    props: {
        products: {
            type: Array
        },
        date: { type: String }
    },
    computed: {
        forecast() {
            return this.$store.getters['production/getForecast']
        },
        currentTimeframe() {
            if (moment().isSame(this.date, 'day')) {
                const currentHour = moment(this.currentTime).hour()
                const currentMinute = moment(this.currentTime).minute()
                let hour = `${currentHour}:00`
                if (currentMinute >= 30) {
                    hour = `${currentHour}:30`
                }
                if (hour.length == 4) {
                    hour = '0' + hour
                }
                return hour
            }
            return false
        },
        summary() {
            if (this.forecast.summary && this.forecast.sales_forecast) {
                var current_level = false
                var current_forecast = 0

                if (true || (moment(this.date).isSame(moment(), 'day') && this.forecast.pmix_real && this.forecast.pmix_real.levels)) {
                    var key = false
                    if (moment(this.date).isSame(moment(), 'day')) {
                        key = moment(Math.floor(moment().unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    } else {
                        key = moment(Math.floor(moment(this.date).add('days', 1).unix() / 1800) * 1800 * 1000).format('YYYY-MM-DD HH:mm:ss')
                    }

                    for (var idx in this.forecast.sales_forecast.general.sales_forecast) {
                        if (idx < key) {
                            current_forecast += this.forecast.sales_forecast.general.sales_forecast[idx]
                            // current_forecast += this.forecast.sales_forecast.delivery.sales_forecast[idx]
                        }
                    }

                    current_forecast = Math.round(current_forecast, 2)
                }

                var current_sales = this.forecast.sales_real ? this.forecast.sales_real.general.total : 0

                return {
                    date: moment(this.forecast.summary.ref_general).isValid() ? moment(this.forecast.summary.ref_general).format('DD/MM/YYYY') : this.forecast.summary.ref_general,
                    date_delivery: moment(this.forecast.summary.ref_delivery).isValid() ? moment(this.forecast.summary.ref_delivery).format('DD/MM/YYYY') : this.forecast.summary.ref_delivery,
                    base: this.forecast.summary.sales_base,
                    forecast: Math.round(this.forecast.summary.total),
                    current_forecast: current_forecast,
                    current_sales: current_sales,
                    diff: current_sales - current_forecast,
                    current: this.forecast.sales_real ? this.forecast.sales_real.general.total : 0,
                    current_delta: Math.round(((current_sales - current_forecast) / current_forecast) * 100)
                }
            }
            return {}
        },
        summary_hours2() {
            var hours = {}
            let position = 0
            let inserted = 0
            let last_value = 0
            const ALERT_FACTOR = this.forecast.sales_color_factor ? this.forecast.sales_color_factor : 1.3
            if (Object.keys(this.forecast.products.pls).length > 0) {
                for (var idx in Object.values(this.forecast.products.pls)[0].hours) {
                    if (position >= this.start_hour && inserted < this.max_hours) {
                        let keyname = this.forecast.business_date + ' ' + moment(idx).format('HH:mm:ss')
                        let value = this.forecast.sales_forecast.general.sales_forecast[keyname]
                        let update_value = 0

                        if (last_value == 0) {
                            last_value = this.forecast.sales_forecast.general.sales_forecast[this.forecast.business_date + ' ' + moment(idx).subtract(30, 'minutes').format('HH:mm:ss')]
                        }

                        if (last_value > 0) {
                            if (value > last_value * ALERT_FACTOR) {
                                update_value = 1
                            } else if (last_value > value * ALERT_FACTOR) {
                                update_value = -1
                            }
                        }
                        hours[moment(idx).format('HH:mm')] = update_value
                        last_value = value

                        inserted += 1
                    }
                    position += 1
                }
            }

            return hours
        },
        summary_hours_complete() {
            var hours = {}
            if (Object.keys(this.forecast.products.pls).length > 0) {
                for (var idx in Object.values(this.forecast.products.pls)[0].hours) {
                    hours[moment(idx).format('HH:mm')] = 0
                }
            }
            return hours
        },
        filteredProducts() {
            return this.products.filter((product) => [6].includes(product.type))
        }
    },
    methods: {
        getTotal(productId) {
            var product = this.filteredProducts.find((item) => item.id === productId)
            var units = product.packages[product.active_container].units
            var factor = 1
            var result = {}

            if (this.forecast && this.forecast.products && typeof this.forecast.products.pls[productId] !== 'undefined') {
                var last_value = 0
                const factor = product.pls_factor
                for (var idx in this.forecast.products.pls[productId].hours) {
                    var key = moment(idx).format('HH:mm')
                    var totalValue = this.forecast.products.pls[productId].hours[idx]

                    if (totalValue === 0) {
                        result[key] = {
                            baths: '-',
                            units: '-',
                            state: ''
                        }
                    } else {
                        var completeBaths = Math.floor(totalValue / units)
                        var remainingUnits = totalValue - completeBaths * units
                        if (remainingUnits < 0) {
                            remainingUnits = 0
                        } else {
                            remainingUnits = Math.ceil(remainingUnits)
                        }

                        result[key] = {
                            baths: completeBaths || '-',
                            units: remainingUnits || '-',
                            state: totalValue * (1 + factor) < last_value ? 'decrease' : totalValue * (1 - factor) > last_value ? 'increase' : ''
                        }

                        if (!moment(this.date).isSame(moment(), 'day') || moment(idx).hour() < moment().hour()) {
                            result[key].state = ''
                        }
                    }
                    last_value = totalValue
                }
            } else {
                for (var idx in this.summary_hours2) {
                    result[idx] = {
                        baths: '-',
                        units: '-',
                        state: ''
                    }
                }
            }

            return result
        },
        scrollLeft() {
            var value = this.start_hour - 1
            if (value < 0) {
                value = 0
            }
            this.start_hour = value
        },
        scrollRight() {
            var value = this.start_hour + 1
            if (value + this.max_hours > 40) {
                value = 40 - this.max_hours
            }
            this.start_hour = value
        },
        toggleSalesSection() {
            this.isExpanded = !this.isExpanded

            var self = this
            this.$nextTick(() => {
                if (self.$refs.scrollContainer4) {
                    self.$refs.scrollContainer4.scrollTo({ top: 0, behavior: 'smooth' })
                }
            })
        },
        refresh() {
            this.isExpanded = false
            this.loadForecast()
        },
        loadForecast() {
            let self = this
            this.$overlay.loading()
            this.$store.dispatch('production/loadForecast', this.date).then(() => {
                self.$overlay.hide()
            })
        },
        updateCurrentTime() {
            this.currentTime = new Date()
            var hour = this.currentTimeframe
            if (hour) {
                this.start_hour = indexOf(Object.keys(this.summary_hours_complete), hour)
            }
        }
    },
    created() {
        this.timeInterval = setInterval(() => {
            this.updateCurrentTime()
        }, 60000)

        this.updateCurrentTime()
    },
    beforeDestroy() {
        if (this.timeInterval) {
            clearInterval(this.timeInterval)
        }
    }
}
</script>
<style lang="scss" scoped>
.table {
    display: flex;
    flex-direction: column;
    height: 100%;

    .positive {
        font-family: $text-bold !important;
        color: $color-success-500;
    }
    .negative {
        font-family: $text-bold !important;
        color: $color-error-500;
    }

    .topbar {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-bottom: 10px;

        .content {
            display: flex;
            width: calc(70% + 30px);
            justify-content: flex-end;
            overflow-x: auto;
        }

        button {
            height: 50px;
            width: 50px;
            font-size: 30px;
            background-color: #fff;
        }
        .sales-information {
            width: calc(30% - 30px);
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            flex-shrink: 0;
            height: 50px;
            gap: 8px;

            .nav {
                border-radius: 4px;
                display: flex;
                align-self: flex-start;
                width: 50px;
                height: 50px;
                align-items: center;
                &.sales-button {
                    @include background($image: img('chart_inactive.svg'), $size: 20px, $color: #fff);
                }
                &.active {
                    &.sales-button {
                        @include background($image: img('chart_active.svg'), $size: 20px, $color: $main);
                    }
                }
                &.mail {
                    @include background($image: img('mail.svg'), $size: 25px, $color: #fff);
                }
                &.refresh {
                    @include background($image: img('reload.svg'), $size: 20px, $color: #fff);
                }
            }
        }
        .navBar {
            width: calc(100% - 100px);
            display: flex;
            justify-items: start;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;

            .nav-elements {
                //width: 250px;
                @include display-flex();
                @include align-items();
                gap: 8px;
                width: 100%;
                border-radius: 4px;
                height: 48px;

                &.increase {
                    border: 4px solid rgba($color-warning-500, 1);
                }
                &.decrease {
                    border: 4px solid rgba($color-error-500, 1);
                }

                &.total {
                    width: 125px;
                    align-items: center;
                    justify-content: center;
                }

                .printer {
                    border-radius: 4px;
                    display: flex;
                    align-self: flex-start;
                    width: 50px;
                    height: 50px;
                    align-items: center;
                    @include background($image: img('printer_b3b3b3.svg'), $size: 20px, $color: #fff);
                }
                .timeframe {
                    @include display-flex();
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    padding: 4px 0;
                    border-radius: 4px;

                    &.current {
                        background: rgba($color-warning-500, 1);

                        .title {
                            color: #fff;
                        }
                        .subtitles {
                            .subtitle {
                                color: #fff;
                            }
                        }
                    }

                    .title {
                        text-transform: uppercase;
                        color: $color-black;
                        @include font-size(m);
                        font-family: $text-bold;
                    }

                    .subtitles {
                        display: flex;
                        flex-direction: row;
                        gap: 8px;
                        width: 100%;
                        justify-content: space-evenly;

                        .subtitle {
                            color: $color-neutral-600;
                            @include font-size(xs);
                            font-family: $text;
                            text-align: center;
                            min-width: 25px;
                        }
                    }
                }
            }

            .nav {
                border-radius: 4px;
                width: 50px;
                height: 50px;

                &.left {
                    @include background($image: img('left_dark.svg'), $size: 20px, $color: #fff);
                }
                &.right {
                    @include background($image: img('right_dark.svg'), $size: 20px, $color: #fff);
                }
            }
            .hours {
                width: calc(100% - 100px);
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                overflow-x: hidden;
                scroll-behavior: smooth;
                gap: 10px;
                padding: 0 0px;

                display: grid;
                justify-items: start;
                grid-template-columns: repeat(3, 1fr);
                align-items: center;
                justify-items: center;
                overflow: hidden;

                .scroll-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 80px;
                    height: 100%;
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;
                    margin-left: 5px;

                    p {
                        font-family: $text;
                        font-size: 20px;
                        color: $color-neutral-600;
                    }
                }
                & .selected {
                    border: 2px solid $color-warning-500;
                    p {
                        font-family: $text-bold;
                    }
                }
            }
        }
        .summary-title {
            text-align: center;
            width: 125px;
            flex-shrink: 0;

            span {
                word-wrap: break-word;
                @include font-size(m);
                color: $color-neutral-600;
                font-family: $text-bold;
                text-transform: uppercase;
            }
        }
    }
    .content-sections {
        height: calc(100% - 50px);
        overflow: auto;
        .sales-section {
            width: 100%;
            display: flex;
            flex-direction: row;
            .sales-text {
                width: 30%;
                padding-left: 40px;
                span {
                    display: block;
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
            .sales-content {
                width: 70%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                box-sizing: border-box;
                overflow: hidden;
                .sales-forecast {
                    width: calc(100% - 100px);
                    display: grid;
                    flex-direction: column;
                    grid-template-rows: repeat(4, 1fr);
                    align-items: center;
                    overflow-x: hidden;
                    overflow-y: hidden;
                    scroll-behavior: smooth;

                    .row {
                        display: grid;
                        flex-direction: row;
                        gap: 25px;
                        width: 100%;
                        grid-template-columns: repeat(3, 1fr);
                        text-align: center;

                        span {
                            text-align: center;
                        }

                        .scroll-item {
                            flex: 0 0 80px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100px;
                            max-width: 100px;
                            min-width: 100px;
                            margin-left: 5px;

                            span {
                                text-align: center;
                                width: 100px;
                                font-family: $text;

                                &.positive {
                                    font-family: $text-bold;
                                    color: $color-success-500;
                                }
                                &.negative {
                                    font-family: $text-bold;
                                    color: $color-error-500;
                                }
                            }
                        }
                    }
                }
            }
            .sales-summary {
                width: 125px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                span {
                    font-family: $text;
                    display: flex;
                    align-items: center;
                    height: 35px;
                }
            }
        }
        .product-content {
            display: flex;
            flex-direction: row;
            width: 100%;

            .products {
                width: 30%;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .item {
                    width: 100%;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    height: 60px;

                    &:hover {
                        background-color: rgba(255, 255, 255, 0.6);
                    }
                    &.frost {
                        &:hover {
                            background-color: $main-t90;
                        }
                    }

                    .icon {
                        width: 35px;
                        height: 35px;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 90%;
                        display: inline-block;
                    }
                    .content {
                        flex-direction: column;
                        width: 100%;
                        padding-left: 8px;
                        .name {
                            display: flex;
                            align-items: center;
                            font-family: $text-bold;
                            text-transform: uppercase;
                            @include font-size(sm);
                        }
                        .icon {
                            /* background-image: img('/img/defrost.svg'); */
                            height: 25px;
                            width: 25px;
                        }
                        .container {
                            display: block;
                            font-family: $text;
                            color: $color-neutral-600;
                            display: block;
                        }
                    }
                }
            }

            .content {
                display: flex;
                width: 70%;
                justify-content: flex-end;

                .forecast {
                    width: calc(100% - 190px);
                    margin-right: 60px;
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    align-items: stretch;
                    flex-direction: column;
                    gap: 10px;

                    .item {
                        height: 50px;
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        flex-direction: row;
                        overflow-x: hidden;
                        scroll-behavior: smooth;
                        padding: 0;
                        gap: 16px;
                        width: 100%;
                        height: 60px;
                        align-items: start;

                        .value-container {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            height: 50px;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 4px;
                            border: 2px solid transparent;
                            border-radius: 4px;
                            position: relative;
                            // padding: 4px;
                            padding-top: 10px;

                            &.increase {
                                .value {
                                    border-color: $color-success-500;
                                    color: $color-success-500;
                                }
                            }

                            &.decrease {
                                .value {
                                    border-color: $color-error-500;
                                    color: $color-error-500;
                                }
                            }

                            .updated {
                                width: 0;
                                height: 0;
                                border-left: 8px solid transparent;
                                border-right: 8px solid transparent;
                                border-radius: 4px;
                                position: absolute;
                                bottom: -10px;

                                &.up {
                                    border-bottom: 8px solid $color-success-500;
                                }
                                &.down {
                                    border-top: 8px solid $color-error-500;
                                }
                            }

                            &.current {
                                height: 60px;
                                padding-top: 0;

                                .value {
                                    // border-color: $color-warning-500;
                                    // background: rgba($color-warning-500, 1);
                                    // color: #fff;
                                    @include font-size(l);
                                }
                            }

                            .value {
                                font-family: $text;
                                @include font-size(m);
                                line-height: 1.2;
                                text-align: center;
                                min-width: 25px;
                                background: #fff;
                                border: 2px solid #ddd;
                                padding: 4px;
                                border-radius: 4px;
                                width: 100%;
                                height: 100%;
                                align-items: center;
                                display: flex;
                                justify-content: center;

                                &.baths {
                                    font-family: $text-bold;
                                }
                            }
                        }
                    }
                }
            }
            .summary {
                width: 125px;
                text-align: center;
                .item {
                    width: 100%;
                    height: 75px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    .value {
                        font-family: $text-bold;
                        @include font-size(l);
                        text-transform: uppercase;
                        text-align: center;
                    }
                }
            }
        }
    }
}

//RBI DEVICE
@media (max-width: 1285px) {
    .table {
        .topbar {
            .navBar {
                .nav-elements {
                    .timeframe {
                        .title {
                            @include font-size(sm);
                        }
                        .description {
                            @include font-size(xs);
                        }
                    }
                    .printer {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
    }
}

@media (orientation: portrait) {
    .table {
        .content-sections {
            .product-content {
                .products {
                    width: 40%;
                    .item {
                        height: 75px;
                        .icon {
                            width: 60px;
                            height: 60px;
                            background-size: 90%;
                        }
                        .content {
                            .name {
                                @include font-size(l);
                            }
                            .container {
                                @include font-size(sm);
                                margin-top: 5px;
                            }
                        }
                    }
                }
                .content {
                    width: 60%;
                    .forecast {
                        width: calc(100% - 90px);

                        .item {
                            height: 75px;
                            .value-container {
                                height: 60px;
                                .value {
                                    @include font-size(l);
                                }
                                &.current {
                                    height: 70px;
                                    .value {
                                        @include font-size(xl);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .topbar {
            .navBar {
                .nav-elements {
                    .timeframe {
                        .title {
                            @include font-size(l);
                        }
                    }
                }
            }
        }
    }
}
</style>
